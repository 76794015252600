import React from 'react';
import ReactDOM from 'react-dom'
import 'react-tooltip/dist/react-tooltip.css'

import App from './App';
import './index.css';

ReactDOM.render(
    <div style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
<App/>
</div>
, document.getElementById('root'))