import email from '../assets/email.png';
import css from '../assets/css.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import umass from '../assets/umass.svg';

export default {
  email,
  css,
  html,
  javascript,
  python,
  react,
  sass,
  profile,
  circle,
  logo,
  umass,
  

  
};